$mobileSize: 900px;

[page="contact"] {
    .maps {
        position: relative;
        overflow: hidden;
        margin-top: 30px;
        height: 500px;

        .gmap_canvas {
            overflow: hidden;
            background: none !important;
            height: 100%;
            width: 100%;
        }

        .mapouter{position:relative;text-align:right;height:100%;width:100%;}
    }

    .heading-left {
        display: flex;
        flex-direction: column;

        .title {
            color: #fff;
            font-weight: 500;
            font-size: 30px;
        }

        .subtitle {
            font-size: 17px;
            margin-top: 20px;
        }
    }


    .core-small-contact {
        padding-top: 30px;

        .section-title {
            font-weight: 500;
            font-size: 26px;
            color: var(--main-color);
            width: fit-content;
            margin: auto;
            display: flex;
        }

        .contacts {
            margin: auto;
            display: grid;
            align-items: center;
            width: 600px;
            margin: auto;
            max-width: 100%;
            margin-top: 30px;
            grid-template-columns: repeat(auto-fill, minmax(calc(50% - 30px), 1fr));
            gap: 10px;

            .contact {
                font-size: 18px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: center;

                .name {
                    font-weight: 500;
                    color: #000;
                    text-align: center;
                }

                a {
                    color: var(--main-color);
                    font-weight: 500;
                    text-align: center;
                }
            }
        }
    }
}