$mobileSize: 900px;

[page="meet-our-team"] {

    .teamsbox {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 30px), 1fr));
        gap: 20px;
        width: calc(100% - 30px);
        margin-left: 15px;


        @media screen and (min-width: $mobileSize) {
            width: 800px;
            margin: auto;
            margin-top: 30px;
            max-width: 100%;
        }

        @media screen and (max-width: $mobileSize) {
            grid-template-columns: repeat(auto-fill, minmax(calc(100% - 30px), 1fr));
        }

        .team {
            padding: 10px;
            border: .7px solid rgb(232, 232, 232);
            border-radius: 10px;
            overflow: hidden;
            transition: .2s ease;
            display: flex;
            flex-direction: column;

            &:hover {
                box-shadow: 2px 2px 1px 3px rgba(0,0,0,0.5);
                margin-top: 2px;
                margin-left: 2px;
            }

            .avatar {
                width: 100%;
                border-radius: 10px;
            }

            .name {
                color: var(--main-color);
                font-weight: 500;
                font-size: 18px;
                text-align: center;
                margin-top: 10px;
            }

            .position {
                color: var(--main-color);
                text-align: center;
                margin-top: 5px;
                font-size: 15px;
            }

            .socials {
                display: flex;
                width: fit-content;
                margin: auto;
                margin-top: 10px;
                gap: 8px;
                
                .social {
                    text-decoration: none !important;
                    transition: .2s ease;

                    &:hover {
                        margin-top: -10px;
                    }
                    
                    .icon {
                        width: 30px;
                    }
                }
            }

        }
    }

    .mot-heading-left {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 500;
            font-size: 30px;
        }

        .description {
            margin-top: 20px;
            font-size: 18px;
            font-weight: 400;
        }
    }

    .head-of-section {
        display: flex;
        width: fit-content;
        margin: auto;
        flex-direction: column;
        max-width: 100%;
        color: var(--main-color);
        padding-top: 30px;

        .title {
            text-align: center;
            font-weight: 500;
            font-size: 28px;
        }


        .descriptions {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            * {
                text-align: center;
            }
            
            .d {
                display: flex;
                gap: 3px;
                width: fit-content;
                margin: auto;
                font-size: 16px;
            }

            .bold-red {
                color: rgb(250, 250, 250);
                background: var(--main-color);
                padding: 0px 6px;
            }
        }
    }
}