$mobileSize: 900px;

[page="merchandise"] {

    .product_items {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 30px), 1fr));
        gap: 20px;
        width: calc(100% - 30px);
        margin-left: 15px;
        
        @media only screen and (max-width: $mobileSize) {
            grid-template-columns: repeat(auto-fill, minmax(calc(100% - 30px), 1fr));
        }

        .product {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: 300px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 3px 5px 10px 3px #0000002b;
            transition: 0.1s ease-in-out all;
            cursor: pointer;
            text-decoration: none;
            position: relative;
            overflow: hidden;
            border: 1px solid transparent;

            * {
                text-decoration: none !important;
                color: #000;
            }

            &:hover {
                box-shadow: 3px 5px 5px 3px #00000069;
                border: 1px solid var(--main-color);
            }
            
            .product_image {
                width: 100%;
            }

            .content {
                display: flex;
                flex-direction: column;
                padding: 10px 16px;

                .title {
                    font-size: 16px;
                    color: var(--main-color);
                    font-weight: 500;
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .sold {
                    white-space: nowrap;
                    font-size: 12px;
                }

                .prices {
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    gap: 7px;

                    .price {
                        white-space: nowrap;
                        display: flex;
                        align-items: end;
                        font-size: 15px;
                        
                        .currency {
                            font-size: 12px;
                        }
                    }

                    .separator {
                        background: #000000b0;
                        height: 1px;
                        width: 7px;
                    }
                }
            }
        }
    }

    .teamsbox {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 30px), 1fr));
        gap: 20px;
        width: calc(100% - 30px);
        margin-left: 15px;


        @media screen and (min-width: $mobileSize) {
            width: 800px;
            margin: auto;
            margin-top: 30px;
            max-width: 100%;
        }

        @media screen and (max-width: $mobileSize) {
            grid-template-columns: repeat(auto-fill, minmax(calc(100% - 30px), 1fr));
        }

        .team {
            padding: 10px;
            border: .7px solid rgb(232, 232, 232);
            border-radius: 10px;
            overflow: hidden;
            transition: .2s ease;
            display: flex;
            flex-direction: column;

            &:hover {
                box-shadow: 2px 2px 1px 3px rgba(0,0,0,0.5);
                margin-top: 2px;
                margin-left: 2px;
            }

            .avatar {
                width: 100%;
                border-radius: 10px;
            }

            .name {
                color: var(--main-color);
                font-weight: 500;
                font-size: 18px;
                text-align: center;
                margin-top: 10px;
            }

            .position {
                color: var(--main-color);
                text-align: center;
                margin-top: 5px;
                font-size: 15px;
            }

            .socials {
                display: flex;
                width: fit-content;
                margin: auto;
                margin-top: 10px;
                gap: 8px;
                
                .social {
                    text-decoration: none !important;
                    transition: .2s ease;

                    &:hover {
                        margin-top: -10px;
                    }
                    
                    .icon {
                        width: 30px;
                    }
                }
            }

        }
    }

    .mot-heading-left {
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 500;
            font-size: 30px;
        }

        .description {
            margin-top: 20px;
            font-size: 18px;
            font-weight: 400;
        }
    }

    .head-of-section {
        display: flex;
        width: fit-content;
        margin: auto;
        flex-direction: column;
        max-width: 100%;
        color: var(--main-color);
        padding-top: 30px;

        .title {
            text-align: center;
            font-weight: 500;
            font-size: 28px;
        }


        .descriptions {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            * {
                text-align: center;
            }
            
            .d {
                display: flex;
                gap: 3px;
                width: fit-content;
                margin: auto;
                font-size: 16px;
            }

            .bold-red {
                color: rgb(250, 250, 250);
                background: var(--main-color);
                padding: 0px 6px;
            }
        }
    }
}