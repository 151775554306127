[page="privacy-policy"] {
    .pp-heading {
        display: flex;
        flex-direction: column;
        gap: 14px;
        .title {
            font-weight: 600;
            font-size: 28px;

            @media screen and (max-width: 900px) {
                text-align: center;
            }
        }
    }

    .docs {
        width: 600px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        padding-top: 15px;
        padding-bottom: 15px;

        .v-bold {
            font-weight: 600;
            font-size: 20px;
            color: var(--main-color);
        }

        .bold {
            font-weight: 500;
            font-size: 18px;
            margin-top: 6px;
            color: var(--main-color);
        }

        p {
            font-weight: 400;
            font-size: 16px;
        }
    }
}