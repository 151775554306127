[page="terms-and-conditions"] {
    .tac-heading {
        .text {
            font-weight: 500;
            font-size: 28px;
        }
    }

    .doc {
        width: 600px;
        max-width: 100%;
        display: flex;
        flex-direction:column;
        margin: auto;
        padding-top:30px;
        padding-bottom: 30px;


        .bold {
            font-weight: 500;
            font-size: 16px;
            color: var(--main-color);
        }

        p {
            font-size: 14px;
        }
    }
}