.header {
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
    transition: 0.1s ease;
    color: var(--main-color);
    padding: 6px 15px;
    color: var(--main-color);
    box-shadow: 0 4px 10px rgba(0,0,0,.1);
    width: calc(100vw - 70px);
    left: 10px;
    top: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;

    &[top-position="0"] {
        background: transparent;
        color: #fff;
        box-shadow: unset;
        width: calc(100vw - 49px);
        left: unset;
        top: 0;
        border-radius: 0;
    }

    .left {
        display: flex;
        width: fit-content;
        align-items: center;
        background: transparent;
        
        .app {
            display: flex;
            align-items: center;
            background: transparent;
            gap: 10px;
            color: unset !important;
            text-decoration: none !important;

            .logo {
                width: 30px;
                border-radius: 6px;
            }

            .name {
                font-weight: 700;
                font-size: 25px;
                color: unset;
            }
        }
    }
}