.heading {
    background: var(--main-color);
    color: #fff;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 100px;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }

    .left-component-container {
        flex: 1;
    }

    .main-img {
        max-width: 100%;
        width: 500px;
    }
}