.sidebar {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 10px;
    background: transparent;
    width: fit-content;
    align-items: center;

    .mobile-mode {
        
        .menu-toggle {
            cursor: pointer;
        }

        .container {
            transition: 0.1s ease;
            position: fixed;
            z-index: 1000;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: #fff;

            #close-btn {
                background: transparent;
                outline: none !important;
                border: none !important;
                color: var(--main-color) !important;
                position: absolute;
                top: 20px;
                right: 30px;
                cursor: pointer;
                background: transparent;
            }
            
            .menus {
                margin-top: 60px;
                overflow-y: auto;
                overflow-x: hidden;
                height: calc(100vh - 60px);
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 100px;
                gap: 20px;

                .menu {
                    width: fit-content;
                    display: flex;
                    margin-left: auto;
                    margin-right: auto;
                    text-decoration: none !important;
                    color: var(--main-color) !important;
                    font-weight: 500;
                    font-size: 22px;
                    position: relative;

                    &::after {
                        content: '';
                        display: flex;
                        position: absolute;
                        height: 3px;
                        border-radius: 5px;
                        width: 100%;
                        margin-top: 25px;
                    }

                    &.active::after {
                        background: var(--main-color);
                    }
                }
            }
        }   
    }

    .desktop-mode {
        display: flex;
        align-items: center;
        
        .actions {
            display: flex;
            align-items: center;
            margin-left: 15px;
            gap: 10px;

            .sign-up-btn {
                background: transparent;
                color: var(--main-color) !important;
                background: #fff;
                outline: none !important;
                border: 1px solid var(--main-color);

                &.top-0 {
                    color: #fff !important;
                    background: var(--main-color);
                    border-color: #fff;
                }

                &:hover {
                    background: rgba(0,0,0,0.1);
                }
            }
            
            .sign-in-btn {
                background: transparent;
                color: #fff !important;
                background: var(--main-color);
                outline: none !important;
                border: 1px solid #fff;

                &.top-0 {
                    color: var(--main-color) !important;
                    background: #fff !important;
                    border-color: var(--main-color) !important;
                }

                &:hover {
                    opacity: .9;
                }
            }
        }

        .pages {
            display: flex;
            align-items: center;
            gap: 15px;

            .page {
                text-decoration: none !important;
                color: unset;
                position: relative;

                .name {
                    font-weight: 500;
                    font-size: 16px;
                }


                &::after,
                &::after {
                    content: '';
                    transition: 0.2s ease;
                    display: flex;
                    position: absolute;
                    height: 3px;
                    width: 0;
                    position: absolute;
                    bottom: 0;
                    left: 0%;
                    top: 23px;
                    border-radius: 5px;
                    background: var(--main-color)
                }

                &[top="0"]::after {
                    background: #fff;
                }

                &:hover::after,
                &.active::after {
                    width: 100%;
                }
            }
        }
    }
}