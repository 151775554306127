$mobileSize: 900px;
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.confirmation_form {
   width: 100%;
   .head {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      * {
         width: 100%;
      }
   }
}

[page="merch_detail"] {
    .merch {
      display: flex;
      width: calc(100% - 30px);
      margin: auto;
      max-width: 800px;
      background: #fff;
      margin-top: 30px;
      box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.259);
      margin-bottom: 30px;
      border-radius: 3px;

      .description {
         white-space: pre-line;
         padding: 6px 10px;
      }

      .left {
         flex: .3;
      }

      .right {
         flex: .7;
         padding: 10px 20px;

         .primary-action {
            margin-top: 20px;
            display: flex;
            align-items: center;
         }

         .price {
            font-weight: 500;
            font-size: 18px;
            display: flex;
            flex: 1;
            color: var(--main-color);
            margin-bottom: 10px;
         }

         .merch_name {
            font-weight: 500;
            color: #000;
            font-size: 20px;
         }

         .flex-information {
            display: flex;
            align-items: center;

            .sold {
               display: flex;
               align-items: center;
               gap: 5px;
               font-size: 13px;
               .val {
                  font-weight: 500;
                  font-size: 15px;
               }
            }
         }
      }
    }
}