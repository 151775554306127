html,
body,
#root,
.app,
#root > div {
    box-sizing: border-box;
    background: rgb(250, 250, 250);
    padding: 0 !important;
    margin: 0 !important;
}

html {
    scroll-behavior: smooth;
}