$mobileSize: 900px;

[page="about-us"] {
    @media screen and (min-width: 900px) {
        .heading-left-text {
            div {
                margin-left: 30px;
                // padding-left: 30px;
            }
        }
    }

    .trusted {
        margin: auto;
        margin-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        width: 700px;
        max-width: 100%;

        .title {
            color: #000;
            text-align: center;
            font-size: 23px;
        }

        .description {
            color: dimgray;
            text-align: center;
            font-size: 17px;
            margin-top: 20px;
            padding-bottom: 30px;
        }
    }

    .simple-about {
        background:rgb(250, 250, 250);
        color: #000;
        padding-top: 30px;
        padding-bottom: 30px;

        .section-title {
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            letter-spacing: 1px;
        }


        @media screen and (min-width: $mobileSize) {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: 1300px;
            max-width: 100%;
        }

        .items {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 30px;

            .item {
                display: flex;
                align-items: center;
                gap: 30px;

                @media screen and (max-width: $mobileSize) {
                    flex-direction: column;
                }

                .ilustration {
                    width: 500px;
                    max-width: 100%;
                }

                .information {
                    padding: 10px 40px;
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-weight: 500;
                        font-size: 18px;
                    }

                    .description {
                        margin-top: 10px;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}