$mobileSize: 900px;

.bank_checkout_section {
  margin-top: 20px;
}

[page="about-us"] {
  .keranjang_data {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 6px 10px;

    section#checkout {
      margin-top: 30px;
      display: flex;
      margin: auto;
      width: fit-content;
      margin-top: 30px;
      flex-direction: column;
      justify-content: center;

      .need_to_be_paid {
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 5px;

        .icon {
          color: var(--main-color);
        }

        .amount {
          display: flex;
          align-items: center;
          white-space: nowrap;
          gap: 5px;
          font-weight: 500;
          color: var(--main-color);
        }
      }
      
      .btn_continue {
        display: flex;
        width: fit-content;
        margin: auto;
        margin-top: 15px;
      }
    }

    .data_container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .card {
        border-radius: 10px;
        position: relative;

        &:not(:hover) {
          button[aria-isc="1"] {
            display: none;
          }
        }

        .delete_btn {
          position: absolute;
          top: 10px;
          right: 10px;
          color: var(--main-color);
        }

        .h {
          img {
            border-radius: 10px;
          }
        }

        .card_header__title {
          font-weight: 500;
        }

        .card_header__pcs {
          font-size: 12px;
        }

        footer {
          display: flex;
          align-items: center;
          width: 100%;

          .cart_variant__name {
            color: dimgray;
            font-weight: 500;
          }

          .cart_footer__price {
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-right: 0;
            font-weight: 500;
            color: #000;
          }
        }
      }
    }
  }
}
