.main-footer {
    padding: 20px 40px;
    background: #F5FAFF;

    @media screen and (max-width: 900px) {
        padding: 6px 10px;
        padding-bottom: 20px;
    }

    .separator {
        width: calc(100% - 50px);
        margin-left: 25px;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 2px;
        background: rgba(0,0,0,0.2);
    }

    .copyright {
        color: #000;
        white-space: nowrap;
        max-width:100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .media-icons {
        color: #000;
        display: flex;
        gap: 5px;
        align-items: center;
        width: fit-content;
        margin-left: auto;
        margin-right: 0;

        a {
            color: #000 !important;
            text-decoration: none !important;
            transition: 0.1s ease margin;

            .icon {
                width: 30px;
            }

            &:hover {
                margin-top: -5px;
            }
        }
    }

    .informations {
        display: flex;
        margin-top: 20px;

        @media screen and (max-width: 900px) {
            flex-direction: column;
            gap: 20px;
        }

        .information-links {
            flex: .5;
            color: #000;
            display: flex;
            flex-direction: column;
            gap: 15px;

            ._links {
                
                .title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000;
                }

                .urls {
                    display: flex;
                    margin-top: 10px;
                    flex-direction: column;
                    color: #000 !important;

                    .url {
                        text-decoration: underline;
                        color: #000;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .app-information {
            flex: .5;

            .app {
                display: flex;
                align-items: center;
                gap: 10px;
                text-decoration: none !important;

                .logo {
                    width: 40px;
                    border-radius: 10px;
                }

                .name {
                    color: var(--main-color)
                }
            }

            & > p {
                color: #000;
                margin-top: 10px;
                &:first-child {
                    margin-top: 2px;
                }
            }

            .contact-link {
                display: flex;
                align-items: center;
                gap: 5px;
                color: #000;

                a {
                    text-decoration: none !important;
                    color: var(--main-color);
                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }
        }
    }
}